import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetSignupInfoErrorResponse,
  GetSignupInfoRequest,
  GetSignupInfoResponse,
  GetUserResponse,
  LoginArgs,
  LoginRequestBodyGoogleCode,
  LoginRequestBodyUsernamePassword,
  LoginResponseErrorValidation,
  LoginResponseSuccess,
  PostUserLoginRequest,
  PutChangePasswordErrorResponse,
  PutChangePasswordRequest,
  PutForgotPasswordErrorResponse,
  PutForgotPasswordRequest,
  PutForgotPasswordResponse,
  PutResetPasswordErrorResponse,
  PutResetPasswordRequest,
  getSignupInfo,
  getUser,
  login,
  postUserLogin,
  putChangePassword,
  putForgotPassword,
  putResetPassword,
} from "#app-services/api/login";
import { ReactQueryKeys } from "#app-services/hooks/constants";
import { ServiceHook } from "#app-services/hooks/types";
import { setAuthToken } from "#app-services/utils/auth/jwt";
import {
  clear2faRememberDeviceToken,
  get2faRememberDeviceToken,
  set2faRememberDeviceToken,
} from "#app-services/utils/auth/two-factor";
import { logError, logFatal } from "#app-services/utils/logger";

type UseLoginError = {
  code: LoginResponseErrorValidation["code"];
};

type UseLogin = ServiceHook<undefined, UseLoginError> & {
  login: (body: LoginArgs) => void;
};

function useLogin(): UseLogin {
  const { isPending, isSuccess, isError, error, mutate } = useMutation<
    LoginResponseSuccess,
    UseLoginError,
    LoginArgs,
    undefined
  >({
    mutationKey: [ReactQueryKeys.login],
    mutationFn: async (body: LoginArgs) => {
      if (body.type === "GOOGLE_CODE") {
        if (!body.body.code) {
          throw {
            code: "MISSING_AUTH_CODE",
          };
        }
      }
      const response = await login(body);

      if (!response.success) {
        if (response.data.type === "validation") {
          throw {
            code: response.data.code,
          };
        } else {
          throw {
            code: "GENERIC_ERROR",
          };
        }
      }

      return response.data;
    },
    onSuccess: (data) => {
      setAuthToken(data.token);
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    login: mutate,
  };
}

type UseLoginUsernamePasswordMutationArgs = Omit<
  LoginRequestBodyUsernamePassword,
  "method"
>;

type UseLoginUsernamePassword = ServiceHook<undefined, UseLoginError> & {
  login: (args: UseLoginUsernamePasswordMutationArgs) => void;
};

export function useLoginUsernamePassword(): UseLoginUsernamePassword {
  const { login, ...rest } = useLogin();

  return {
    ...rest,
    login: useCallback(
      (args: UseLoginUsernamePasswordMutationArgs) => {
        login({
          type: "USERNAME_PASSWORD",
          body: {
            ...args,
            method: "pwd",
          },
        });
      },
      [login]
    ),
  };
}

type UseLoginRequestBodyGoogleCodeMutationArgs = Omit<
  LoginRequestBodyGoogleCode,
  "method"
>;

type UseLoginGoogleCode = ServiceHook<undefined, UseLoginError> & {
  login: (args: UseLoginRequestBodyGoogleCodeMutationArgs) => void;
};

export function useLoginGoogleCode(): UseLoginGoogleCode {
  const { login, ...rest } = useLogin();

  return {
    ...rest,
    login: useCallback(
      (args: UseLoginRequestBodyGoogleCodeMutationArgs) => {
        login({
          type: "GOOGLE_CODE",
          body: {
            ...args,
            method: "google",
          },
        });
      },
      [login]
    ),
  };
}

export type UseGetUser = ServiceHook<GetUserResponse, null> & {
  refetch: () => void;
};

export function useGetUser(): UseGetUser {
  const { isLoading, isFetching, isSuccess, isError, data, refetch } = useQuery(
    {
      queryKey: [ReactQueryKeys.getUser],
      queryFn: async () => {
        const response = await getUser();

        if (!response.success) {
          throw new Error();
        }

        return response.data;
      },
      staleTime: Infinity,
    }
  );

  return {
    isLoading: isFetching,
    isInitialLoading: isLoading,
    isSuccess,
    isError,
    error: null,
    data,
    refetch,
  };
}

type UsePutForgotPasswordError = {
  code: PutForgotPasswordErrorResponse["code"];
};

export type UsePutForgotPassword = ServiceHook<
  PutForgotPasswordResponse,
  UsePutForgotPasswordError
> & {
  putForgotPassword: (request: PutForgotPasswordRequest) => void;
};

export function usePutForgotPassword(): UsePutForgotPassword {
  const { isPending, isSuccess, isError, mutate, data, error } = useMutation<
    PutForgotPasswordResponse,
    UsePutForgotPasswordError,
    PutForgotPasswordRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.putForgotPassword],
    mutationFn: async (request: PutForgotPasswordRequest) => {
      const response = await putForgotPassword(request);

      if (!response.success) {
        throw {
          code: response.data.code,
        };
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    data,
    putForgotPassword: mutate,
  };
}

type UsePutResetPasswordError = {
  code: PutResetPasswordErrorResponse["code"];
};

export type UsePutResetPassword = ServiceHook<
  undefined,
  UsePutResetPasswordError
> & {
  putResetPassword: (request: PutResetPasswordRequest) => void;
};

export function usePutResetPassword(): UsePutResetPassword {
  const { isPending, isSuccess, isError, mutate, error } = useMutation<
    undefined,
    UsePutResetPasswordError,
    PutResetPasswordRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.putResetPassword],
    mutationFn: async (request: PutResetPasswordRequest) => {
      const response = await putResetPassword(request);

      if (!response.success) {
        if (response.data.code === "PASSWORD_NOT_STRONG_ENOUGH") {
          logError({
            message:
              "Unexpected error during the password strength validation.",
          });
        }
        throw {
          code: response.data.code,
        };
      }
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    putResetPassword: mutate,
  };
}

export type UsePutChangePasswordError =
  | {
      type: "schemaValidation";
    }
  | { type: "apiError"; code: PutChangePasswordErrorResponse["code"] };

export type UsePutChangePassword = ServiceHook<
  undefined,
  UsePutChangePasswordError
> & {
  putChangePassword: (request: PutChangePasswordRequest) => void;
};

export function usePutChangePassword(): UsePutChangePassword {
  const { isPending, isSuccess, isError, error, mutate } = useMutation<
    undefined,
    UsePutChangePasswordError,
    PutChangePasswordRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.putChangePassword],
    mutationFn: async (request: PutChangePasswordRequest) => {
      const response = await putChangePassword(request);

      if (!response.success) {
        if (response.data.code === "PASSWORD_NOT_STRONG_ENOUGH") {
          logError({
            message:
              "Unexpected error during the password strength validation.",
          });
        }

        if (
          ["INVALID_USERNAME_PASSWORD", "PASSWORD_NOT_STRONG_ENOUGH"].includes(
            response.data.code
          )
        ) {
          throw {
            type: "schemaValidation",
          };
        }

        throw {
          type: "apiError",
          code: response.data.code,
        };
      }
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    putChangePassword: mutate,
  };
}

export type UseUserLoginData =
  | "NEEDS_2FA"
  | "NEEDS_CHANGE_PASSWORD"
  | "USER"
  | "BAD_2FA"
  | "SUCCESS";

type UsePostUserLogin = ServiceHook<UseUserLoginData, UseLoginError> & {
  login: (request: PostUserLoginRequest) => void;
};

export function usePostUserLogin(): UsePostUserLogin {
  const { isPending, isSuccess, isError, error, mutate, data } = useMutation<
    UseUserLoginData,
    UseLoginError,
    PostUserLoginRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.postUserLogin],
    mutationFn: async (body: PostUserLoginRequest) => {
      const { withRememberDevice, ...request } = body;
      const rememberDeviceToken = withRememberDevice
        ? get2faRememberDeviceToken()
        : undefined;

      const response = await postUserLogin({
        ...request,
        rememberDeviceToken,
      });

      if (!response.success) {
        if (response.data.type === "validation") {
          if (body.token) {
            return "BAD_2FA";
          } else {
            throw {
              code: response.data.code,
            };
          }
        } else {
          throw {
            code: "GENERIC_ERROR",
          };
        }
      }

      const jwt = response.data.jwt.split(".");
      let decodedToken: { role: string };

      try {
        decodedToken = JSON.parse(atob(jwt[1]));
      } catch (error) {
        logFatal({
          message: "Unexpected Error: The JWT token was not valid to parse",
          exception: error as Error,
        });
        throw {
          code: "JWT_PARSER_ERROR",
        };
      }

      setAuthToken(response.data.jwt);

      if (decodedToken.role === "2fa") {
        return "NEEDS_2FA";
      } else {
        if (body.trustThisDevice === false) {
          clear2faRememberDeviceToken();
        }

        if (response.data.rememberDeviceToken) {
          set2faRememberDeviceToken(response.data.rememberDeviceToken);
        }

        if (response.data.changePassword) {
          return "NEEDS_CHANGE_PASSWORD";
        }
      }

      return "SUCCESS";
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    data,
    login: mutate,
  };
}

type UsePostRequestNew2faCode = ServiceHook<undefined, UseLoginError | null> & {
  requestNew2faCode: (request: PostUserLoginRequest) => void;
};

export function usePostRequestNew2faCode(): UsePostRequestNew2faCode {
  const { isPending, isSuccess, isError, mutate, error } = useMutation<
    UseUserLoginData,
    UseLoginError,
    PostUserLoginRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.postRequestNew2faCode],
    mutationFn: async (request: PostUserLoginRequest) => {
      const response = await postUserLogin(request);

      if (!response.success) {
        if (response.data.type === "validation") {
          throw {
            code: response.data.code,
          };
        }

        throw {
          code: "GENERIC_ERROR",
        };
      }

      return "SUCCESS";
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    requestNew2faCode: mutate,
  };
}

type UseGetSignupInfoError = {
  code: GetSignupInfoErrorResponse["code"];
};

type UseGetSignupInfo = ServiceHook<
  GetSignupInfoResponse,
  UseGetSignupInfoError
> & {
  getSignupInfo: (request: GetSignupInfoRequest) => void;
};

export function useGetSignupInfo(): UseGetSignupInfo {
  const { isPending, isSuccess, isError, mutate, data, error } = useMutation<
    GetSignupInfoResponse,
    UseGetSignupInfoError,
    GetSignupInfoRequest,
    undefined
  >({
    mutationKey: [ReactQueryKeys.getSignupInfo],
    mutationFn: async (request: GetSignupInfoRequest) => {
      const response = await getSignupInfo(request);

      if (!response.success) {
        throw {
          code: response.data.code,
        };
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    data,
    getSignupInfo: mutate,
  };
}
