import { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Input, InputProps } from "@pl/app-component-lib";
import { noop } from "#app-common/utils/noop";

export type RhfInputProps = {
  id: string;
  label: string;
  // Trailing args is an escape hatch for the time being, in order to support
  // the new common FormField type using RhfInput (since the validation
  // signature on the new FormField type is different from this one)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate: (value?: string, ...args: any[]) => Promise<boolean | string>;
  onChange?: (changed: Record<string, string | undefined>) => void;
  deps?: string[];
  type?: InputProps["type"];
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  loading?: boolean;
  maskOnBlur?: boolean;
  optional?: boolean;
  className?: string;
  maxLength?: number;
  minLength?: number;
  hintMessage?: string;
  tooltip?: InputProps["tooltip"];
};

export function RhfInput({
  id,
  type,
  placeholder,
  defaultValue,
  label,
  disabled,
  loading,
  optional,
  maskOnBlur,
  className,
  maxLength,
  minLength,
  hintMessage,
  tooltip,
  deps,
  validate,
  onChange = noop,
}: RhfInputProps) {
  const { formatMessage } = useIntl();

  const { control } = useFormContext();
  const { field, fieldState } = useController({
    defaultValue,
    name: id,
    control,
    rules: { validate, deps },
  });

  const { onChange: rhfOnChange, onBlur, ref, name, value } = field;
  const { error, isTouched } = fieldState;

  useEffect(() => {
    onChange({ [id]: value });
  }, [value, id, onChange]);

  return (
    <div className={className} data-testid={`field-${id}`}>
      <Input
        ref={ref}
        id={id}
        type={type || "text"}
        error={error?.message}
        isValid={!error && isTouched}
        placeholder={placeholder}
        label={
          optional
            ? `${label} ${formatMessage({ id: "global.field.label.optional" })}`
            : label
        }
        onChange={rhfOnChange}
        onBlur={onBlur}
        name={name}
        value={value}
        disabled={disabled}
        loading={loading}
        maskOnBlur={maskOnBlur}
        maxLength={maxLength}
        minLength={minLength}
        hintMessage={hintMessage}
        tooltip={tooltip}
      />
    </div>
  );
}
