import { IntlShape, MessageDescriptor } from "react-intl";
import { DEFAULT_SELECT_VALUE } from "@pl/app-component-lib";

type AllPossibleErrorCodes =
  | "STRING_EMPTY"
  | "STRING_DEFAULT_SELECT"
  | "NUMBER_INVALID"
  | "NUMBER_NEGATIVE"
  | "NUMBER_ZERO"
  | "EMAIL_INVALID"
  | "NUMBER_DECIMAL";

export type ValidatorArgs<T = unknown> = {
  value?: string;
  opts?: T;
  formatMessage?: IntlShape["formatMessage"];
};

export type ValidateNonEmptyStringErrorCodes = Extract<
  AllPossibleErrorCodes,
  "STRING_EMPTY" | "STRING_DEFAULT_SELECT"
>;

export async function validateNonEmptyString({
  value,
  formatMessage,
}: ValidatorArgs): Promise<string | boolean> {
  let code: ValidateNonEmptyStringErrorCodes | null = null;
  if (!value?.trim()) {
    code = "STRING_EMPTY";
  } else if (value === DEFAULT_SELECT_VALUE) {
    code = "STRING_DEFAULT_SELECT";
  }

  if (!code) {
    return true;
  }

  if (formatMessage) {
    return formatMessage({ id: `code.ui.${code}` as MessageDescriptor["id"] });
  }
  return code;
}

export type ValidateNonDefaultSelectValueErrorCodes = Extract<
  AllPossibleErrorCodes,
  "STRING_DEFAULT_SELECT"
>;

export async function validateNonDefaultSelectValue({
  value,
  formatMessage,
}: ValidatorArgs): Promise<string | boolean> {
  let code: ValidateNonDefaultSelectValueErrorCodes | null = null;
  if (value === DEFAULT_SELECT_VALUE) {
    code = "STRING_DEFAULT_SELECT";
  }

  if (!code) {
    return true;
  }

  if (formatMessage) {
    return formatMessage({ id: `code.ui.${code}` as MessageDescriptor["id"] });
  }
  return code;
}

export type ValidateNumberErrorCodes = Extract<
  AllPossibleErrorCodes,
  "NUMBER_INVALID" | "NUMBER_NEGATIVE" | "NUMBER_ZERO" | "NUMBER_DECIMAL"
>;

export async function validateNumber({
  value,
  opts = { nonzero: true, wholeNumber: false },
  formatMessage,
}: ValidatorArgs<{ nonzero?: boolean; wholeNumber?: boolean }>): Promise<
  string | boolean
> {
  let code: ValidateNumberErrorCodes | null = null;
  const num = parseFloat(value || "");
  if (Number.isNaN(num)) {
    code = "NUMBER_INVALID";
  } else if (num < 0) {
    code = "NUMBER_NEGATIVE";
  } else if (num === 0 && opts?.nonzero) {
    code = "NUMBER_ZERO";
  } else if (opts?.wholeNumber && !Number.isInteger(num)) {
    code = "NUMBER_DECIMAL";
  }

  if (!code) {
    return true;
  }

  if (formatMessage) {
    return formatMessage({ id: `code.ui.${code}` as MessageDescriptor["id"] });
  }
  return code;
}

export type ValidateEmailErrorCodes = Extract<
  AllPossibleErrorCodes,
  "EMAIL_INVALID"
>;

export async function validateEmail({
  value,
  formatMessage,
}: ValidatorArgs): Promise<string | boolean> {
  let code: ValidateEmailErrorCodes | null = null;
  if (
    !value
      ?.toLowerCase()
      ?.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    code = "EMAIL_INVALID";
  }

  if (!code) {
    return true;
  }

  if (formatMessage) {
    return formatMessage({ id: `code.ui.${code}` as MessageDescriptor["id"] });
  }
  return code;
}
