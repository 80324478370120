import axios from "axios";
import { useAuthStore } from "#app-services/stores/useAuthStore";
import { getAuthToken } from "#app-services/utils/auth/jwt";
import { EventBus } from "../utils/eventBus";
import { ApiEndpoints } from "./endpoints";

export const PAYOR_HEADER_KEY = "x-payor-id";
const SkipInjectPayorHeaderRoutes = [
  ApiEndpoints.getUsersPersonas(),
  ApiEndpoints.putSwitchAccess(),
  ApiEndpoints.putDefaultCheckoutInstrument("[id]"),
  "service-payment-import",
];
const SkipInjectAuthHeaderRoutes = [
  "login",
  "verygoodproxy",
  "service-payment-import",
  ApiEndpoints.putForgotPassword(),
  ApiEndpoints.putResetPassword(),
  ApiEndpoints.putPayeeResendInvitation(),
];

const instance = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_BASEURL });

instance.interceptors.request.use(
  (request) => {
    const { url } = request;

    if (
      !SkipInjectAuthHeaderRoutes.some((endpoint) => url?.includes(endpoint))
    ) {
      const authToken = getAuthToken();

      if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`;
      }
    }

    if (
      request.url &&
      !SkipInjectPayorHeaderRoutes.some((endpoint) => url?.includes(endpoint))
    ) {
      const payorId = useAuthStore.getState().activeAccount?.payorId;
      if (payorId) {
        request.headers[PAYOR_HEADER_KEY] = payorId;
      }
    }
    return request;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.url.includes("verygoodproxy")
    ) {
      EventBus.getInstance().dispatch<"401", undefined>("401");
    }

    return Promise.reject(error);
  }
);

export { instance };
