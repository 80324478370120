import cx from "classnames";
import { Badge, BadgeProps } from "#app-component-lib/data-display/Badge/Badge";
import { InfoIcon } from "#app-component-lib/icons/outline/InfoIcon";
import { Tooltip } from "#app-component-lib/overlay/Tooltip/Tooltip";

export type FieldLabelProps = {
  text: string;
  wrapperMargin?: "none" | "sm" | "md";
  labelFontSize?: "md" | "sm";
  tooltip?: {
    id: string;
    message: string;
    triggerTitle: string;
  };
  badge?: BadgeProps & {
    id: string;
    asStatus?: boolean;
  };
} & (
  | {
      as: "label";
      htmlFor: string;
    }
  | {
      as: "span";
      id: string;
    }
);

export function FieldLabel({
  as,
  text,
  tooltip,
  badge,
  wrapperMargin,
  labelFontSize,
  ...props
}: FieldLabelProps) {
  const labelClassNames = buildLabelClassNames(labelFontSize);

  return (
    <span className={buildLabelWrapperClassNames(wrapperMargin)}>
      {as === "label" && (
        <label {...props} className={labelClassNames}>
          {text}
        </label>
      )}
      {as === "span" && (
        <span {...props} className={labelClassNames}>
          {text}
        </span>
      )}
      {tooltip && (
        <Tooltip tooltipId={tooltip.id} message={tooltip.message}>
          <InfoIcon
            size="sm"
            color="default"
            interactive
            trigger
            title={tooltip.triggerTitle}
          />
        </Tooltip>
      )}
      {badge && (
        <span
          {...(badge.asStatus ? { role: "status" } : undefined)}
          id={badge.id}
        >
          <Badge
            status={badge.status}
            text={badge.text}
            size={badge.size}
            Icon={badge.Icon}
          />
        </span>
      )}
    </span>
  );
}

export function buildLabelClassNames(
  labelFontSize?: FieldLabelProps["labelFontSize"]
) {
  return cx("text-txt-label", {
    "text-label-md": labelFontSize === "md" || !labelFontSize,
    "text-label-sm": labelFontSize === "sm",
  });
}

export function buildLabelWrapperClassNames(
  wrapperMargin?: FieldLabelProps["wrapperMargin"]
) {
  return cx("flex items-center gap-x-3", {
    "mb-1": wrapperMargin === "sm" || !wrapperMargin,
    "mb-3": wrapperMargin === "md",
  });
}
