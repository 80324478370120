import { forwardRef, useState } from "react";
import cx from "classnames";
import {
  CurrentMaxLength,
  useCurrCharCount,
} from "#app-component-lib/form/common/CurrentMaxLength/CurrentMaxLength";
import { FieldGutterNew } from "#app-component-lib/form/common/FieldGutter";
import { FieldLabel } from "#app-component-lib/form/common/FieldLabel";
import {
  FieldState,
  buildAriaDescribedBy,
  buildBaseInputClassNames,
  computeFieldState,
  maskValueWithAsterisks,
} from "#app-component-lib/form/common/utils";
import { CheckIcon } from "#app-component-lib/icons/outline/CheckIcon";

export type InputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  id: string;
  label: string;
  type:
    | "text"
    | "email"
    | "password"
    | "date"
    | "time"
    | "number"
    | "month"
    | "url";
  name?: string;
  value?: string;
  placeholder?: string;
  hintMessage?: string;
  validMessage?: string;
  isValid?: boolean;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  maxLength?: number;
  minLength?: number;
  maskOnBlur?: boolean;
  ariaDescribedBy?: string;
  tooltip?: {
    message: string;
    triggerTitle: string;
  };
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      onBlur,
      id,
      type = "text",
      disabled,
      loading,
      error,
      placeholder,
      label,
      value = "",
      hintMessage,
      name,
      ariaDescribedBy,
      isValid,
      validMessage,
      tooltip,
      maxLength,
      minLength,
      maskOnBlur,
    },
    ref
  ) => {
    const [focusState, setFocusState] = useState<"focused" | "blurred">(
      "blurred"
    );
    const { currCharCount, onCurrCharCountChange } = useCurrCharCount(value);
    const fieldState = computeFieldState(
      isValid,
      Boolean(error),
      disabled || loading
    );
    const showCharCount = maxLength && fieldState !== "disabled";
    const showValidIcon = fieldState === "valid" && type !== "date";
    const ttId = tooltip ? `input-${id}-tt` : undefined;

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (maxLength) {
        onCurrCharCountChange(event.target.value);
      }
      onChange(event);
    }

    return (
      <>
        <FieldLabel
          as="label"
          htmlFor={id}
          text={label}
          tooltip={tooltip ? { ...tooltip, id: ttId as string } : undefined}
        />
        <div className="relative">
          <input
            id={id}
            name={name}
            type={type}
            ref={ref}
            aria-describedby={cx(
              buildAriaDescribedBy({
                fieldState,
                ariaDescribedBy,
                hasHintMessage: Boolean(hintMessage),
                hasValidMessage: Boolean(validMessage),
                hasErrorMessage: Boolean(error),
                fieldId: id,
                tooltipId: ttId,
              }),
              {
                "char-count-maxlength": showCharCount,
              }
            )}
            aria-invalid={fieldState === "error"}
            data-is-valid={fieldState === "valid"}
            data-is-error={fieldState === "error"}
            className={buildInputClassNames(fieldState, showValidIcon, loading)}
            value={
              focusState === "blurred" && maskOnBlur
                ? maskValueWithAsterisks(value)
                : value
            }
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            disabled={disabled || loading}
            onChange={handleInputChange}
            onBlur={() => {
              setFocusState("blurred");
              onBlur?.();
            }}
            onFocus={() => setFocusState("focused")}
          />
          {showValidIcon && (
            <div className="absolute bottom-0 right-3 top-0">
              <div className="flex size-full items-center">
                <CheckIcon size="md" color="positive" />
              </div>
            </div>
          )}
        </div>
        <FieldGutterNew
          id={id}
          fieldState={fieldState}
          hintMessage={hintMessage}
          validMessage={validMessage}
          errorMessage={error}
        >
          {showCharCount && (
            <CurrentMaxLength
              currCharCount={currCharCount}
              maxLength={maxLength as number}
            />
          )}
        </FieldGutterNew>
      </>
    );
  }
);
Input.displayName = "Input";

function buildInputClassNames(
  state: FieldState,
  withRightDecoration?: boolean,
  loading?: boolean
) {
  const base = buildBaseInputClassNames(
    state,
    withRightDecoration,
    true,
    loading
  );

  return cx(base, "text-int-txt-form");
}
