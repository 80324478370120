import { JSX } from "react";
import cx from "classnames";

export const MessageStatus = [
  "info",
  "positive",
  "negative",
  "warning",
] as const;

export type MessageProps = {
  status: (typeof MessageStatus)[number];
  body?: string | JSX.Element;
  bodyAs?: "p" | "div";
  title?: string | JSX.Element;
  titleAs?: "p" | "div";
};

export function Message({
  status,
  title,
  titleAs = "p",
  body,
  bodyAs = "p",
}: MessageProps) {
  return (
    <div
      data-testid="message"
      className={cx("rounded-xs border-l-4 px-8 py-4", {
        "border-border-info bg-bg-info-light": status === "info",
        "border-border-positive bg-bg-positive-light": status === "positive",
        "border-border-negative bg-bg-negative-light": status === "negative",
        "border-border-warning bg-bg-warning-light": status === "warning",
      })}
    >
      <MessageTitle title={title} titleAs={titleAs} />
      <MessageBody body={body} bodyAs={bodyAs} />
    </div>
  );
}

function MessageTitle({
  title,
  titleAs,
}: Pick<MessageProps, "title" | "titleAs">) {
  if (!title) {
    return null;
  }

  const titleClassName = cx("text-txt-heading text-title-md mb-3");

  return (
    <>
      {titleAs === "p" && <p className={titleClassName}>{title}</p>}
      {titleAs === "div" && <div className={titleClassName}>{title}</div>}
    </>
  );
}

function MessageBody({ body, bodyAs }: Pick<MessageProps, "body" | "bodyAs">) {
  if (!body) {
    return null;
  }

  const bodyClassName = cx("text-body-sm text-txt-body");

  return (
    <>
      {bodyAs === "p" && <p className={bodyClassName}>{body}</p>}
      {bodyAs === "div" && <div className={bodyClassName}>{body}</div>}
    </>
  );
}
