import { ComponentType } from "react";
import cx from "classnames";
import { IconProps } from "#app-component-lib/icons/types";

export const BadgeStatus = ["info", "positive", "negative", "warning"] as const;
export type BadgeProps = {
  text: string;
  status: (typeof BadgeStatus)[number];
  size?: "sm" | "lg";
  Icon?: ComponentType<IconProps>;
};

export function Badge({ text, status, size = "sm", Icon }: BadgeProps) {
  return (
    <span
      className={cx(
        "text-txt-inverse flex w-fit items-center gap-x-1 rounded-2xl px-3 py-0.5 font-semibold",
        {
          "text-body-xs": size === "sm",
          "text-body-sm": size === "lg",
          "bg-bg-info": status === "info",
          "bg-bg-positive": status === "positive",
          "bg-bg-negative": status === "negative",
          "bg-bg-warning": status === "warning",
        }
      )}
    >
      {Icon && <Icon size="sm" color="inverse" />}
      {text}
    </span>
  );
}
