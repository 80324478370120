import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../localStorage";

const REMEMBER_DEVICE_TOKEN_KEY = "pl-2fa-device";

export function get2faRememberDeviceToken(): string | undefined {
  return getLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY) || undefined;
}

export function set2faRememberDeviceToken(token: string): void {
  setLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY, token);
}

export function clear2faRememberDeviceToken(): void {
  removeLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY);
}
