import { AxiosError, AxiosResponse } from "axios";
import { ConfigEncoder } from "@paymentlabs/utils-model-external";
import { instance } from "#app-services/api/config";
import { ApiEndpoints } from "#app-services/api/endpoints";
import { ServiceResponse } from "#app-services/api/types";
import { getAuthToken } from "#app-services/utils/auth/jwt";
import { logError } from "#app-services/utils/logger";

export type LoginRequestBodyUsernamePassword = {
  username: string;
  password: string;
  method: string;
};

export type LoginRequestBodyTokenExchange = {
  method: "external";
  provider: "payment-labs-legacy";
  key: string;
  data: string;
};

export type LoginRequestBodyGoogleCode = {
  method: "google";
  code: string;
  redirectUrl: string;
};

export type LoginResponseSuccess = {
  token: string;
};

export type LoginResponseErrorValidation = {
  code?:
    | "INVALID_USERNAME_PASSWORD"
    | "MISSING_USERNAME"
    | "MISSING_PASSWORD"
    | "MISSING_PROVIDER"
    | "MISSING_KEY"
    | "INVALID_PROVIDER"
    | "INVALID_USER"
    | "MISSING_KEY"
    | "MISSING_SECRET"
    | "INVALID_KEY_OR_SECRET"
    | "INVALID_KEY"
    | "MISSING_CODE"
    | "MISSING_REDIRECT_URL"
    | "GOOGLE_ERROR_UNKNOWN"
    | "GOOGLE_ERROR_MISSING_ACCESS_TOKEN"
    | "GOOGLE_ERROR_MISSING_EMAIL"
    | "GOOGLE_NO_ACCESS"
    | "GOOGLE_ERROR_CANT_GET_USER_DATA"
    | "GOOGLE_ERROR_PROVIDER_ERROR"
    | "INVALID_USER"
    | "INVALID_METHOD"
    | "NO_ACCESS_TO_GRANT"
    | "USER_NOT_FOUND"
    | "GENERIC_ERROR"
    | "VALIDATION_FAILED"
    | "MISSING_AUTH_CODE"
    | "JWT_PARSER_ERROR"
    | "FORBIDDEN";
  message?: string;
};

export type LoginResponseError =
  | ({
      type: "validation";
    } & LoginResponseErrorValidation)
  | { type: "failure" };

export type LoginArgs =
  | {
      type: "USERNAME_PASSWORD";
      body: LoginRequestBodyUsernamePassword;
    }
  | {
      type: "TOKEN_EXCHANGE";
      body: LoginRequestBodyTokenExchange;
    }
  | {
      type: "GOOGLE_CODE";
      body: LoginRequestBodyGoogleCode;
    };

export async function login({
  body,
}: LoginArgs): Promise<
  ServiceResponse<LoginResponseSuccess, LoginResponseError>
> {
  let response: AxiosResponse<LoginResponseSuccess>;

  const url = ApiEndpoints.login();

  try {
    response = await instance.post(url, body);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: LoginResponseError = { type: "failure" };
    if (status === 400) {
      data = {
        type: "validation",
        ...(response?.data as LoginResponseErrorValidation),
      };
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type GetUserResponse = {
  email: string;
  firstName: string;
  lastName: string;
};

export async function getUser(): Promise<
  ServiceResponse<GetUserResponse, null>
> {
  let response: AxiosResponse<GetUserResponse>;
  const url = ApiEndpoints.getUser();

  try {
    response = await instance.get(url);
  } catch (e) {
    const error = e as AxiosError;
    const { message, status } = error;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export function getGoogleLoginUrl() {
  const authUrl = process.env.NEXT_PUBLIC_AUTH_URL;
  const authType = process.env.NEXT_PUBLIC_AUTH_TYPE;
  const scope = process.env.NEXT_PUBLIC_AUTH_SCOPE;
  const redirectUrl = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URL;
  const clientId = process.env.NEXT_PUBLIC_AUTH_CLIENT_ID;

  return `${authUrl}?scope=${scope}&redirect_uri=${redirectUrl}&response_type=${authType}&client_id=${clientId}`;
}

export type PutForgotPasswordRequest = {
  email: string;
  notificationType?: "2fa" | "forgot-password";
  payorId?: string;
};

export type PutForgotPasswordResponse = {
  token: string;
};

export type PutForgotPasswordErrorResponse = {
  code: "INVALID_EMAIL" | "FAILURE";
  message?: string;
};

export async function putForgotPassword(
  request: PutForgotPasswordRequest
): Promise<
  ServiceResponse<PutForgotPasswordResponse, PutForgotPasswordErrorResponse>
> {
  let response: AxiosResponse<PutForgotPasswordResponse>;

  const url = ApiEndpoints.putForgotPassword();

  try {
    response = await instance.put(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: PutForgotPasswordErrorResponse = { code: "FAILURE" };
    if (response?.status && [403, 400, 404].includes(response.status)) {
      data = response?.data as PutForgotPasswordErrorResponse;
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PutResetPasswordRequest = {
  email: string;
  password: string;
  token: string;
};

export type PutResetPasswordErrorResponse = {
  code:
    | "VALIDATION_ERROR"
    | "FAILURE"
    | "PASSWORD_NOT_STRONG_ENOUGH"
    | "INVALID_TOKEN";
  message?: string;
};

export async function putResetPassword(
  request: PutResetPasswordRequest
): Promise<ServiceResponse<null, PutResetPasswordErrorResponse>> {
  let response: AxiosResponse<null>;

  const url = ApiEndpoints.putResetPassword();

  try {
    response = await instance.put(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: PutResetPasswordErrorResponse = { code: "FAILURE" };
    if (response?.status && [403, 400].includes(response.status)) {
      data = response?.data as PutResetPasswordErrorResponse;
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: null,
    status: response.status,
    raw: response,
  };
}

export type PutChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export type PutChangePasswordErrorResponse = {
  code:
    | "VALIDATION_ERROR"
    | "INVALID_USERNAME_PASSWORD"
    | "FAILURE"
    | "PASSWORD_NOT_STRONG_ENOUGH";
  message?: string;
};

export async function putChangePassword(
  request: PutChangePasswordRequest
): Promise<ServiceResponse<null, PutChangePasswordErrorResponse>> {
  let response: AxiosResponse<null>;

  const url = ApiEndpoints.putChangePassword();

  try {
    response = await instance.put(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: PutChangePasswordErrorResponse = { code: "FAILURE" };
    if (response?.status && [403, 400].includes(response.status)) {
      data = response?.data as PutChangePasswordErrorResponse;
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: null,
    status: response.status,
    raw: response,
  };
}

export type PostUserLoginRequest = {
  token?: string;
  refresh?: boolean;
  trustThisDevice?: boolean;
  withRememberDevice?: boolean;
  rememberDeviceToken?: string;
  username?: string;
  password?: string;
  payorId?: string;
  checkoutId?: string;
  mode?: "guest";
};

export type PostUserLoginResponse = {
  jwt: string;
  changePassword?: boolean;
  rememberDeviceToken?: string;
};

export async function postUserLogin(
  request: PostUserLoginRequest
): Promise<ServiceResponse<PostUserLoginResponse, LoginResponseError>> {
  let response: AxiosResponse<PostUserLoginResponse>;

  const url = ApiEndpoints.postUserLogin();

  try {
    const { payorId, ...requestBody } = request;

    let authorizationHeader = {};
    if (!request.password && !request.username && request.mode !== "guest") {
      authorizationHeader = {
        Authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : undefined,
      };
    }

    response = await instance.post(url, requestBody, {
      headers: {
        ...authorizationHeader,
        "x-payor-id": payorId || undefined,
      },
    });
  } catch (e) {
    logError({
      message: "HTTP Request Error /users/login",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      exception: e as any,
      data: {
        error: e,
      },
    });

    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: LoginResponseError = { type: "failure" };
    if (response?.status && [403, 400].includes(response.status)) {
      data = {
        type: "validation",
        ...(response?.data as LoginResponseErrorValidation),
      };
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type GetSignupInfoRequest = {
  email: string;
};

export type GetSignupInfoErrorResponse = {
  code: "INVALID_EMAIL" | "FAILURE" | "GENERIC_ERROR";
};

export type GetSignupInfoResponse = {
  isNewUser: boolean;
};

export async function getSignupInfo(
  request: GetSignupInfoRequest
): Promise<ServiceResponse<GetSignupInfoResponse, GetSignupInfoErrorResponse>> {
  let response: AxiosResponse<{ signupInfo: string }>;

  const endpoint = ApiEndpoints.getSignupInfo();
  const url = `${endpoint}?email=${request.email}`;

  try {
    response = await instance.get(url);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: GetSignupInfoErrorResponse = { code: "FAILURE" };
    if (response?.status && [403, 400].includes(response.status)) {
      data = response?.data as GetSignupInfoErrorResponse;
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  const decodedConfig = ConfigEncoder.decodeToObject<GetSignupInfoResponse>(
    response.data.signupInfo
  );

  return {
    success: true,
    data: decodedConfig,
    status: response.status,
    raw: response,
  };
}
