import cx from "classnames";

export type BuildAriaDescribedByArgs = {
  fieldState: FieldState;
  ariaDescribedBy?: string;
  hasHintMessage?: boolean;
  hasValidMessage?: boolean;
  hasErrorMessage?: boolean;
  fieldId?: string;
  tooltipId?: string;
};

export function buildAriaDescribedBy({
  fieldState,
  ariaDescribedBy,
  hasHintMessage,
  hasValidMessage,
  hasErrorMessage,
  fieldId,
  tooltipId,
}: BuildAriaDescribedByArgs) {
  return cx(ariaDescribedBy, tooltipId, {
    [`field-hint-${fieldId}`]:
      hasHintMessage &&
      (fieldState === "default" ||
        fieldState === "disabled" ||
        (!hasValidMessage && !hasErrorMessage)),
    [`field-valid-${fieldId}`]: hasValidMessage && fieldState === "valid",
    [`field-error-${fieldId}`]: hasErrorMessage && fieldState === "error",
  });
}

export type FieldState = "default" | "valid" | "error" | "disabled";

export function computeFieldState(
  isValid?: boolean,
  isError?: boolean,
  isDisabled?: boolean
): FieldState {
  // Ordering matters (ie if both isError and isValid are true, isError will
  // take precedence). Doesn't make sense to be in that state but it's possible
  // if something is misbehaving.
  if (isDisabled) {
    return "disabled";
  }
  if (isError) {
    return "error";
  }
  if (isValid) {
    return "valid";
  }

  return "default";
}

export function buildBaseInputClassNames(
  state: FieldState,
  withRightDecoration?: boolean,
  isHtmlInput?: boolean,
  isLoading?: boolean
) {
  return cx(
    "h-10 w-full px-3 py-2 truncate",
    "rounded-sm border",
    "bg-int-bg-secondary",
    "focus:shadow-spread focus:outline-none",
    "text-label-sm placeholder:text-int-txt-disabled disabled:text-int-txt-disabled",
    "disabled:bg-int-bg-disabled disabled:border-int-border-disabled",
    // Fix for mobile Safari which lightens a disabled input. Couldn't get a
    // clear answer on this, but that's what seems to be the case. Either way
    // this does fix it.
    // https://stackoverflow.com/a/4648315
    "disabled:opacity-100",
    {
      [cx(
        "border-int-border-form",
        "focus:shadow-int-info focus:border-int-border-info"
      )]: state === "default",
      [cx("border-int-border-positive", "focus:shadow-int-positive")]:
        state === "valid",
      [cx("border-int-border-negative", "focus:shadow-int-negative")]:
        state === "error",
      "pr-9": withRightDecoration,
      // Not sure what's happening but it seems input fields in Chrome don't have
      // centered text when a height is set unless a line height is explicitly
      // set. Seeing a lot of conflicting information, but this seems to work.
      "leading-[2.5rem]": isHtmlInput,
      "animate-pulse": isLoading,
    }
  );
}

export function maskValueWithAsterisks(value: string) {
  return value.replace(/./g, "•");
}
