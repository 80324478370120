import { ApiEndpoints } from "#app-services/api/endpoints";

type OtherKeys =
  | "postRequestNew2faCode"
  | "postPaymentMethodBankTransfer"
  | "postPaymentMethodPushToCard"
  | "getRevenueSummary"
  | "uploadFile";

type ApiEndpointsWithOmissions = Omit<
  typeof ApiEndpoints,
  | "postAstraCreateCard"
  | "postSearchBatches"
  | "postSearchPayorPayments"
  | "getImportRows"
  | "getAllImports"
>;

export const ReactQueryKeys: Record<
  keyof ApiEndpointsWithOmissions | OtherKeys,
  string
> = {
  /*
   * Users
   */

  putSwitchAccess: "putSwitchAccess",

  /*
   * Users - Auth
   */

  login: "login",
  postUserLogin: "postUserLogin",
  postRequestNew2faCode: "postRequestNew2faCode",
  putForgotPassword: "putForgotPassword",
  putResetPassword: "putResetPassword",
  putChangePassword: "putChangePassword",

  /**
   * Users - Me
   */

  getUser: "getUser",
  getUsersPersonas: "getUsersPersonas",
  patchUser: "patchUser",

  /*
   * Payee
   */

  getPayee: "getPayee",
  patchPayee: "patchPayee",
  postPayeeInfoDetails: "postPayeeInfoDetails",
  putPayeeResendInvitation: "putPayeeResendInvitation",
  getSignupInfo: "getSignupInfo",

  /*
   * Payee - Payments
   */

  postPaymentMethod: "postPaymentMethod",
  postPaymentMethodBankTransfer: "postPaymentMethodBankTransfer",
  setDefaultPaymentMethod: "setDefaultPaymentMethod",
  deletePaymentMethod: "deletePaymentMethod",
  getAvailablePaymentMethods: "getAvailablePaymentMethods",
  getPaymentMethodDetailsPrepaid: "getPaymentMethodDetailsPrepaid",
  getRecentPayments: "getRecentPayments",
  getClaimTransactionItem: "getClaimTransactionItem",
  postValidateNameOnBankAccount: "postValidateNameOnBankAccount",
  postPushToDebitVerify: "postPushToDebitVerify",
  getPushToDebitSubmitUrl: "getPushToDebitSubmitUrl",
  postPaymentMethodPushToCard: "postPaymentMethodPushToCard",
  cancelPayment: "cancelPayment",
  refundServiceFee: "refundServiceFee",

  /*
   * Payee - Tax Form
   */

  getAvailableTaxForms: "getAvailableTaxForms",
  postTaxForm: "postTaxForm",
  getTaxFormDownloadUrl: "getTaxFormDownloadUrl",
  getTaxReportDownloadUrl: "getTaxReportDownloadUrl",

  /*
   * Payee - IDV
   */

  postRequestVerificationUrl: "postRequestVerificationUrl",
  putVerificationComplete: "putVerificationComplete",

  /*
   * Payor
   */

  getPayor: "getPayor",
  getAllowedDeniedCountries: "getAllowedDeniedCountries",
  getAllowedDeniedProvinces: "getAllowedDeniedProvinces",
  getPayorConfig: "getPayorConfig",

  /*
   * Payor - Batches
   */

  getBatch: "getBatch",
  postCreateBatch: "postCreateBatch",
  patchBatch: "patchBatch",
  postSubmitBatch: "postSubmitBatch",

  /*
   * Payor - Payments
   */

  postCreatePayment: "postCreatePayment",
  postSubmitPayment: "postSubmitPayment",
  postAutoSubmitPayment: "postAutoSubmitPayment",
  patchBatchPayment: "patchBatchPayment",
  putCancelPayment: "putCancelPayment",
  putRemoveAutoSubmit: "putRemoveAutoSubmit",

  /*
   * Payor - Imports
   */

  postCreateImport: "postCreateImport",
  uploadFile: "uploadFile",
  getImport: "getImport",
  postFinishImport: "postFinishImport",
  deleteImport: "deleteImport",

  /*
   * Checkout
   */

  getCheckout: "getCheckout",
  putCheckout: "putCheckout",
  getCheckoutList: "getCheckoutList",
  getVendorDetails: "getVendorDetails",
  getCheckoutInstrumentsAvailable: "getCheckoutInstrumentsAvailable",
  getCheckoutConfig: "getCheckoutConfig",

  /*
   * Checkout - Instrument
   */

  putDefaultCheckoutInstrument: "putDefaultInstrumentMethod",
  deleteCheckoutInstrument: "deleteInstrumentMethod",
  postCheckout: "postCheckout",
  postCheckoutInstrument: "postCheckoutInstrument",
  putCheckoutInstrument: "putCheckoutInstrument",

  /**
   * Subscription
   */
  postSubscriptionPlan: "postSubscriptionPlan",
  postSubscription: "postSubscription",
  deleteSubscription: "deleteSubscription",
  deleteSubscriptionPlan: "deleteSubscriptionPlan",
  getSubscriptionPlanList: "getSubscriptionPlanList",
  getSubscriptionList: "getSubscriptionList",
  getSubscriptionPlanOfferList: "getSubscriptionPlanOfferList",
  postSubscriptionPlanOffer: "postSubscriptionPlanOffer",
  deleteSubscriptionPlanOffer: "deleteSubscriptionPlanOffer",
  getBuyerSubscriptionPlanOffer: "getBuyerSubscriptionPlanOffer",
  getSubscriptionPlanOfferInstrumentAvailable:
    "getSubscriptionPlanOfferInstrumentAvailable",
  getSubscriptionPlan: "getSubscriptionPlan",
  patchSubscriptionPlan: "patchSubscriptionPlan",
  patchSubscriptionPlanOffer: "patchSubscriptionPlanOffer",
  getSubscriptionPlanOffer: "getSubscriptionPlanOffer",
  getBuyerSubscriptionPaymentsById: "getBuyerSubscriptionPaymentsById",
  getBuyerSubscription: "getBuyerSubscription",
  getBuyerInstruments: "getBuyerInstruments",
  getBuyerSubscriptionPayments: "getBuyerSubscriptionPayments",
  getBuyerSubscriptions: "getBuyerSubscriptions",
  deleteBuyerSubscription: "deleteBuyerSubscription",
  getSubscriptionConfig: "getSubscriptionConfig",

  /*
   * Backoffice
   */

  getPayors: "getPayors",
  postSearch: "postSearch",
  getReturnedPayments: "getReturnedPayments",
  getPayorFees: "getPayorFees",
  postRefundPayment: "postRefundPayment",
  getPayorAccounts: "getPayorAccounts",
  getPartnerAccounts: "getPartnerAccounts",
  getAccountDetails: "getAccountDetails",
  getAccountDetailsQuery: "getAccountDetailsQuery",
  getSubAccounts: "getSubAccounts",
  putUserSettings: "putUserSettings",
  getListUserSettings: "getListUserSettings",
  getUserSettings: "getUserSettings",
  deleteUserSettings: "deleteUserSettings",
  getTransactionRows: "getTransactionRows",
  getLedgerBalances: "getLedgerBalances",
  postLedgerWithdraw: "postLedgerWithdraw",
  postLedgerClearWithholdAccount: "postLedgerClearWithholdAccount",
  getLedgerTransfer: "getLedgerTransfer",
  getRevenueSummary: "getRevenueSummary",
  postAccountEntry: "postAccountEntry",
  postValidatePayeePayable: "postValidatePayeePayable",
  postValidatePayeeIDV: "postValidatePayeeIDV",
  putPaymentCategory: "putPaymentCategory",
  postSearchAggregation: "postSearchAggregation",
  postPaymentMethodDetails: "postPaymentMethodDetails",
  getLedgerPayorExport: "getLedgerPayorExport",
  getWaitingCompliance: "getWaitingCompliance",
  postReviewPayment: "postReviewPayment",

  /*
   * Backoffice - Tax
   */

  getListTaxReports: "getListTaxReports",
  postTaxReportGenerate: "postTaxReportGenerate",
  postTaxReportExport: "postTaxReportExport",
  getListExportTaxReport: "getListExportTaxReport",
  getTaxReportExportDownloadUrl: "getTaxReportExportDownloadUrl",
  postTaxReportRunSent: "postTaxReportRunSent",
  putTaxReportRunDoNotSend: "putTaxReportRunDoNotSend",
  postTaxFormDetails: "postTaxFormDetails",
  postReprocessTax: "postReprocessTax",
  postUpdateFiTaxForm: "postUpdateFiTaxForm",
  getBackofficeTaxFormDownloadUrl: "getBackofficeTaxFormDownloadUrl",

  /**
   * Backoffice - Ledger
   */

  getInboundTransfers: "getInboundTransfers",
  postProcessInboundTransfer: "postProcessInboundTransfer",

  /**
   * Merchant
   */
  getMerchant: "getMerchant",

  /**
   * Payor - Me
   */
  getPayorLedgers: "getPayorLedgers",
  getPayorLedger: "getPayorLedger",
  getPayorLedgerExport: "getPayorLedgerExport",

  /**
   * Ledger - Alert Wallet
   */
  getWalletsAlert: "getWalletsAlert",
  getWalletAlert: "getWalletAlert",
  postWalletsRefresh: "postWalletsRefresh",
};

export const POLL_INTERVAL_SUPER_AGGRESSIVE = 1000; // 1s
export const POLL_INTERVAL_AGGRESSIVE = 3000; // 3s
export const POLL_INTERVAL_BACKGROUND = 30000; // 30s
export const POLL_AGGRESSIVE_TIMEOUT = 30000; // 30s
export const POLL_TAKING_LONG_WARNING = 15000; // 15s
export const TIMEOUT_MESSAGE = 3000; // 3s
