import {
  type CancelPaymentRequest,
  type GetLedgerPayorExportRequest,
  type PaymentCategoryRequest,
  type PostPayeeInfoDetailsRequest,
  type PostPaymentMethodDetailsRequest,
  type PostReviewPaymentRequest,
  type RefundServiceFeeRequest,
} from "./backoffice/backoffice";
import {
  type PostTaxFormDetailsRequest,
  type TaxReportsRequest,
} from "./backoffice/tax";

export const ApiEndpoints = {
  /**
   * Users
   */
  putSwitchAccess: () => "/users/switch-access",

  /*
   * Users - Auth
   */

  login: () => "/login",
  postUserLogin: () => "/users/login",
  putForgotPassword: () => "/users/forgot-password",
  putResetPassword: () => "/users/reset-password",
  putChangePassword: () => "/users/change-password",
  getSignupInfo: () => "/users/signup-info",

  /**
   * Users - Me
   */

  getUsersPersonas: () => `/users/me/personas`,
  getUser: () => "/users/me",
  patchUser: () => "/users/me",

  /*
   * Payee
   */

  getPayee: () => "/payees/me",
  patchPayee: () => "/payees/me",
  putPayeeResendInvitation: () => "/payees/resend-invitation",

  /**
   * Payor - Me
   */
  getPayorLedgers: () => "/payors/me/ledgers",
  getPayorLedger: (accountId: string) => `/payors/me/ledgers/${accountId}`,
  getPayorLedgerExport: (accountId: string) =>
    `/payors/me/ledgers/${accountId}/export`,

  /*
   * Payee - Payments
   */

  postPaymentMethod: () => "/payees/me/payment-method",
  setDefaultPaymentMethod: (id: string) => `/payees/me/payment-method/${id}`,
  deletePaymentMethod: (id: string) => `/payees/me/payment-method/${id}`,
  getAvailablePaymentMethods: () => "/payees/me/payment-method/available",
  getPaymentMethodDetailsPrepaid: () =>
    "/payees/me/payment-method/available/prepaid-debit-card-1/details",
  getRecentPayments: () => "/payees/me/payments",
  getClaimTransactionItem: (
    paymentId: string,
    transactionId: string,
    itemId: string
  ) => `/payments/${paymentId}/transactions/${transactionId}/items/${itemId}`,
  postValidateNameOnBankAccount: () =>
    "/utils/ai/validate/name-on-bank-account",
  postPushToDebitVerify: () => "/payees/me/payment-method/push-to-debit/verify",
  getPushToDebitSubmitUrl: () =>
    "/payees/me/payment-method/push-to-debit/submit-url",
  postAstraCreateCard: () => "/v1/cards",

  /*
   * Payee - Tax
   */

  getAvailableTaxForms: () => "/payees/me/tax/available",
  postTaxForm: () => "/payees/me/tax/form",
  getTaxFormDownloadUrl: (taxFormId: string) =>
    `/payees/me/tax/${taxFormId}/download`,
  getTaxReportDownloadUrl: (taxReportId: string) =>
    `/payees/me/tax/report/${taxReportId}`,

  /*
   * Payee - IDV
   */

  postRequestVerificationUrl: () => "/payees/me/verification",
  putVerificationComplete: () => "/payees/me/verification/complete",

  /*
   * Payor
   */

  getPayor: () => "/payors/me",
  getAllowedDeniedCountries: (payorId: string) =>
    `/payors/${payorId}/payment-availability`,
  getAllowedDeniedProvinces: (payorId: string, country?: string) =>
    `/payors/${payorId}/payment-availability${country ? `/${country}` : ""}`,
  getPayorConfig: () => `/payors/config`,

  /*
   * Payor - Batches
   */

  getBatch: (batchId: string) => `/batches/${batchId}`,
  postCreateBatch: () => `/batches`,
  patchBatch: (batchId: string) => `/batches/${batchId}`,
  postSearchBatches: () => "/batches/search",
  postSubmitBatch: (batchId: string) => `/batches/${batchId}/submit`,

  /*
   * Payor - Payments
   */

  postSearchPayorPayments: () => "/payments/search",
  postSubmitPayment: (batchId: string, paymentId: string) =>
    `/batches/${batchId}/payments/${paymentId}/submit`,
  postAutoSubmitPayment: (batchId: string, paymentId: string) =>
    `/batches/${batchId}/payments/${paymentId}/autoSubmit`,
  postCreatePayment: (batchId: string) => `/batches/${batchId}/payments`,
  patchBatchPayment: (batchId: string, paymentId: string) =>
    `/batches/${batchId}/payments/${paymentId}`,
  putCancelPayment: (batchId: string, paymentId: string) =>
    `/batches/${batchId}/payments/${paymentId}/cancel`,
  putRemoveAutoSubmit: (batchId: string, paymentId: string) =>
    `/batches/${batchId}/payments/${paymentId}/removeAutoSubmit`,

  /*
   * Payor - Imports
   */

  postCreateImport: (batchId: string) => `/batches/${batchId}/imports`,
  getImport: (batchId: string, importId: string) =>
    `/batches/${batchId}/imports/${importId}`,
  getAllImports: (batchId: string) => `/batches/${batchId}/imports`,
  getImportRows: (batchId: string, importId: string) =>
    `/batches/${batchId}/imports/${importId}/payments`,
  postFinishImport: (batchId: string, importId: string) =>
    `/batches/${batchId}/imports/${importId}/finish`,
  deleteImport: (batchId: string, importId: string) =>
    `/batches/${batchId}/imports/${importId}`,

  /*
   * Backoffice
   */

  getPayors: () => "/payors",
  postSearch: () => "/search",
  postSearchAggregation: () => "/search",
  getReturnedPayments: () => "/payments/returned",
  getPayorFees: (payorId: string) => `/billing/${payorId}/return`,
  postRefundPayment: (
    batchId: string,
    paymentId: string,
    transactionId: string
  ) =>
    `batches/${batchId}/payments/${paymentId}/transactions/${transactionId}/refund`,
  getAccountDetails: () => "/ledgers",
  getAccountDetailsQuery: () => "/ledgers",
  getPayorAccounts: (payorId: string) =>
    `/ledgers/payor/${payorId}?showArchived=true`,
  getSubAccounts: (accountId: string) => `/ledgers/${accountId}/subledgers`,
  getPartnerAccounts: (partnerType: string) =>
    `/ledgers/partner/${partnerType}`,
  getTransactionRows: () => "/ledgers/transaction",
  getLedgerBalances: (type: string) => `/ledgers/balances/${type}`,
  postLedgerWithdraw: (payorId: string) => `/ledgers/payor/${payorId}/withdraw`,
  postLedgerClearWithholdAccount: (payorId: string, accountId: string) =>
    `/ledgers/payor/${payorId}/withdraw-withholding/${accountId}`,
  getLedgerTransfer: (transactionId: string) =>
    `/ledgers/transfer/${transactionId}`,

  putUserSettings: () => "/users/me/settings",
  getListUserSettings: () => "/users/me/settings",
  getUserSettings: () => "/users/me/settings",
  deleteUserSettings: () => "/users/me/settings",

  postAccountEntry: (accountId: string) => `/ledgers/payor/${accountId}/entry`,
  postValidatePayeePayable: () => `/payees/payability/validate`,
  postValidatePayeeIDV: () => `/payees/verification/check`,
  putPaymentCategory: ({
    batchId,
    paymentId,
    newPaymentCategory,
  }: PaymentCategoryRequest) =>
    `/batches/${batchId}/payments/${paymentId}/category/${newPaymentCategory}`,
  cancelPayment: ({ batchId, paymentId }: CancelPaymentRequest) =>
    `/batches/${batchId}/payments/${paymentId}/cancel-payment-backoffice`,
  refundServiceFee: ({ batchId, paymentId }: RefundServiceFeeRequest) =>
    `/batches/${batchId}/payments/${paymentId}/refund-service-fee`,
  postPaymentMethodDetails: ({
    payorId,
    payeeId,
    paymentMethodId,
  }: PostPaymentMethodDetailsRequest) =>
    `/payees/${payorId}/${payeeId}/payment-method/${paymentMethodId}/details`,
  getLedgerPayorExport: ({
    accountId,
    startDate,
    endDate,
  }: GetLedgerPayorExportRequest) =>
    `/ledgers/payor/export/${accountId}?startDate=${startDate}&endDate=${endDate}`,
  getWaitingCompliance: () => "/payments/waiting-compliance",
  postReviewPayment: ({
    batchId,
    paymentId,
    transactionId,
  }: PostReviewPaymentRequest) =>
    `/batches/${batchId}/payments/${paymentId}/transactions/${transactionId}/review`,
  postPayeeInfoDetails: ({ payorId, payeeId }: PostPayeeInfoDetailsRequest) =>
    `/payees/${payorId}/${payeeId}/details`,

  /*
   * Backoffice - Tax
   */

  postReprocessTax: () => `/payee/tax/zenwork/reprocess`,
  getListTaxReports: ({
    payorId,
    taxJurisdiction,
    taxPeriod,
    lastEvaluatedTaxKey,
    lastEvaluatedTaxReportId,
    scanLimit,
  }: TaxReportsRequest) => {
    let url = `/tax/payor/${payorId}/jurisdiction/${taxJurisdiction}/period/${taxPeriod}?scanLimit=${scanLimit}`;

    if (lastEvaluatedTaxKey && lastEvaluatedTaxReportId) {
      url += `&lastTaxReportId=${lastEvaluatedTaxReportId}&lastTaxKey=${lastEvaluatedTaxKey}`;
    }

    return url;
  },
  postTaxReportGenerate: () => `/tax/generate`,
  postTaxReportExport: () => "/tax/export",
  getTaxReportExportDownloadUrl: (path: string) => `${path}`,
  getListExportTaxReport: ({
    payorId,
    taxJurisdiction,
    taxPeriod,
  }: TaxReportsRequest) =>
    `/tax/export/payor/${payorId}/jurisdiction/${taxJurisdiction}/period/${taxPeriod}`,
  postTaxReportRunSent: (taxReportRunId: string) =>
    `/tax/export/run/${taxReportRunId}/sent`,
  putTaxReportRunDoNotSend: (taxReportRunId: string) =>
    `/tax/report/${taxReportRunId}`,
  postTaxFormDetails: ({
    payorId,
    payeeId,
    taxFormId,
  }: PostTaxFormDetailsRequest) =>
    `/payees/${payorId}/${payeeId}/tax-form/${taxFormId}/details`,
  postUpdateFiTaxForm: (taxFormId: string) => `/payees/tax/form/${taxFormId}`,
  getBackofficeTaxFormDownloadUrl: (
    payorId: string,
    payeeId: string,
    taxFormId: string
  ) => `/payees/${payorId}/${payeeId}/tax/${taxFormId}/download`,

  /**
   * Backoffice - Ledger
   */

  getInboundTransfers: () => "/ledgers/transfer/incoming",
  postProcessInboundTransfer: (id: string) =>
    `/ledgers/transfer/incoming/${id}`,

  /*
   * Checkout
   */

  postCheckout: () => "/checkouts",
  getCheckoutConfig: (checkoutId: string) => `/checkouts/${checkoutId}/config`,
  putCheckout: (checkoutId: string) => `/checkouts/${checkoutId}/charge`,
  getCheckoutList: () => `/checkouts`,
  getCheckout: (checkoutId: string) => `/checkouts/${checkoutId}`,
  getVendorDetails: (checkoutId: string) =>
    `/checkouts/${checkoutId}/vendor-details`,
  getCheckoutInstrumentsAvailable: (checkoutId: string) =>
    `/checkouts/${checkoutId}/checkout-instruments/available`,

  /*
   * Checkout - Instrument
   */
  postCheckoutInstrument: (buyerId: string) =>
    `/buyers/${buyerId}/checkout-instrument`,
  putCheckoutInstrument: (buyerId: string, checkoutInstrumentId: string) =>
    `/buyers/${buyerId}/checkout-instrument/${checkoutInstrumentId}`,
  putDefaultCheckoutInstrument: (checkoutInstrumentId: string) =>
    `/buyers/me/checkout-instruments/default/${checkoutInstrumentId}`,
  deleteCheckoutInstrument: (checkoutInstrumentId: string) =>
    `/buyers/me/checkout-instruments/${checkoutInstrumentId}`,
  getBuyerInstruments: () => `/buyers/me/checkout-instruments`,

  /**
   * Subscriptions
   */
  getSubscriptionPlan: (subscriptionPlanId: string) =>
    `/subscription/plans/${subscriptionPlanId}`,
  postSubscriptionPlan: () => "/subscription/plans",
  patchSubscriptionPlan: (subscriptionPlanId: string) =>
    `/subscription/plans/${subscriptionPlanId}`,
  deleteSubscriptionPlan: (subscriptionPlanId: string) =>
    `/subscription/plans/${subscriptionPlanId}`,
  getSubscriptionPlanList: () => `/subscription/plans`,
  getSubscriptionPlanOfferList: () => `/subscription/plans/offers`,
  postSubscriptionPlanOffer: () => `/subscription/plans/offers`,
  patchSubscriptionPlanOffer: (subscriptionPlanOfferId: string) =>
    `/subscription/plans/offers/${subscriptionPlanOfferId}`,
  deleteSubscriptionPlanOffer: (subscriptionPlanOfferId: string) =>
    `/subscription/plans/offers/${subscriptionPlanOfferId}`,

  getSubscriptionPlanOffer: (subscriptionPlanOfferId: string) =>
    `/subscription/plans/offers/${subscriptionPlanOfferId}`,
  getSubscriptionPlanOfferInstrumentAvailable: (
    subscriptionPlanOfferId: string
  ) => `subscription/plans/offers/${subscriptionPlanOfferId}/available`,
  postSubscription: () => "/subscription",
  deleteSubscription: (subscriptionId: string) =>
    `/subscription/${subscriptionId}`,
  getSubscriptionList: () => "/subscription",

  getBuyerSubscriptionPlanOffer: (subscriptionPlanOfferId: string) =>
    `/subscription/plans/offers/${subscriptionPlanOfferId}/buyer`,
  getBuyerSubscriptionPayments: () => `/subscription/buyer/payments`,
  getBuyerSubscriptionPaymentsById: (subscriptionId: string) =>
    `/subscription/${subscriptionId}/buyer/payments`,
  getBuyerSubscription: (subscriptionId: string) =>
    `/subscription/${subscriptionId}/buyer`,
  getBuyerSubscriptions: () => `/subscription/buyer`,
  deleteBuyerSubscription: (subscriptionId: string) =>
    `/subscription/${subscriptionId}/buyer`,
  getSubscriptionConfig: (subscriptionId: string) =>
    `/subscription/${subscriptionId}/config`,

  /*
   * Merchant
   */
  getMerchant: () => "/merchants/me",

  /**
   * Ledger - Alert Wallet
   */
  getWalletsAlert: () => "/alerts/wallets",
  getWalletAlert: (walletId: string) => `/alerts/wallets/${walletId}`,
  postWalletsRefresh: () => "/alerts/wallets/refresh",
};
