import { IconProps } from "../types";
import { getIconClassNames, getIconA11yAttrs, getIconTitle } from "../utils";

/*
 * Material Icon: Check
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Acheck%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function CheckIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="m395-387 264-263q11-11 25-10.5t25 11.5q11 11 11 25t-11 25L421-311q-11 11-25.5 11T370-311L251-430q-11-11-11-25t11-25q11-11 25-11t25 11l94 93Z" />
    </svg>
  );
}
