import { useState, useCallback } from "react";

export type CurrentMaxLengthProps = {
  currCharCount: number;
  maxLength: number;
};

export function CurrentMaxLength({
  currCharCount,
  maxLength,
}: CurrentMaxLengthProps) {
  return (
    <>
      {maxLength ? (
        <span
          id="char-count-maxlength"
          className="text-label-sm text-txt-subtext"
        >
          {currCharCount}/{maxLength}
        </span>
      ) : null}
    </>
  );
}

export function useCurrCharCount(initialValue?: string) {
  const [currCharCount, setCurrCharCount] = useState<number>(
    initialValue?.length || 0
  );

  const onCurrCharCountChange = useCallback((value?: string) => {
    setCurrCharCount(value?.length || 0);
  }, []);

  return {
    currCharCount,
    onCurrCharCountChange,
  };
}
