import { ReactNode } from "react";
import cx from "classnames";
import { FieldState } from "#app-component-lib/form/common/utils";
import "./FieldGutter.css";

export type FieldGutterProps = {
  id: string;
  hintMessage?: string;
  error?: string;
  validMessage?: string;
  isValid?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
};

export function FieldGutter({
  id,
  hintMessage,
  validMessage,
  error,
  isValid,
  isDisabled,
  children,
}: FieldGutterProps) {
  return (
    <div
      className={cx("field-gutter *:text-label-sm mt-2 flex", {
        "justify-between": children && (hintMessage || validMessage || error),
        "justify-end": children && !(hintMessage || validMessage || error),
      })}
    >
      {hintMessage && !validMessage && !error && (
        <span
          id={`field-hint-${id}`}
          data-testid={`field-hint-${id}`}
          className="text-txt-subtext"
        >
          {hintMessage}
        </span>
      )}
      {isValid && validMessage && !error && !isDisabled && (
        <span
          id={`field-valid-${id}`}
          data-testid={`field-valid-${id}`}
          className="text-txt-positive"
        >
          {validMessage}
        </span>
      )}
      {error && !isDisabled && (
        <span
          id={`field-error-${id}`}
          data-testid={`field-error-${id}`}
          className="text-txt-negative"
        >
          {error}
        </span>
      )}

      {children}
    </div>
  );
}

export type FieldGutterNewProps = {
  id: string;
  fieldState: FieldState;
  hintMessage?: string;
  validMessage?: string;
  errorMessage?: string;
  children?: ReactNode;
  wrapperMargin?: "sm" | "md";
};

export function FieldGutterNew({
  id,
  fieldState,
  hintMessage,
  validMessage,
  errorMessage,
  children,
  wrapperMargin,
}: FieldGutterNewProps) {
  return (
    <div
      // TODO: making this `field-gutter-${id}` introduces test failures on OB
      // form field selectors based on the same testid prefix. Should update all
      // of them to use a new testid prefix.
      data-testid={`gutter-${id}`}
      className={cx("field-gutter *:text-label-sm flex", {
        "justify-between":
          children && (hintMessage || validMessage || errorMessage),
        "justify-end":
          children && !(hintMessage || validMessage || errorMessage),
        "mt-1": wrapperMargin === "sm" || !wrapperMargin,
        "mt-3": wrapperMargin === "md",
      })}
    >
      {hintMessage &&
        (fieldState === "default" ||
          fieldState === "disabled" ||
          (!validMessage && !errorMessage)) && (
          <p
            id={`field-hint-${id}`}
            data-testid={`field-hint-${id}`}
            className="text-txt-subtext"
          >
            {hintMessage}
          </p>
        )}
      {fieldState === "valid" && validMessage && (
        <p
          id={`field-valid-${id}`}
          data-testid={`field-valid-${id}`}
          className="text-txt-positive"
        >
          {validMessage}
        </p>
      )}
      {fieldState === "error" && errorMessage && (
        <p
          id={`field-error-${id}`}
          data-testid={`field-error-${id}`}
          className="text-txt-negative"
        >
          {errorMessage}
        </p>
      )}

      {children}
    </div>
  );
}
